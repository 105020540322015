import { WebLinkType } from '../../model/types';

export const WEB_LINKS_LABELS_MAP: Record<WebLinkType, string> = {
  [WebLinkType.webApplication]: 'Web Application',
  [WebLinkType.webFunnel]: 'Web Funnel',
  [WebLinkType.website]: 'Website',
  [WebLinkType.web2App]: 'Web2App',
};

export const WEB_LINKS_DESCRIPTIONS_MAP: Record<WebLinkType, string> = {
  [WebLinkType.webApplication]:
    'A web-based version of your product that users can interact with directly (e.g., Progressive Web Apps). Monetization depends on the specific product functionality.',
  [WebLinkType.webFunnel]:
    'A series of web pages designed to guide users toward a specific action (e.g., onboarding → sign up → purchase → access the product). Monetization happens on the web (via Stripe, PayPal, etc.). Sometimes referred to as Web2Web.',
  [WebLinkType.website]:
    'A collection of informational web pages about your business, organization, or product, primarily for branding and awareness.',
  [WebLinkType.web2App]:
    'A strategy to convert web users into app users, encouraging them to install and engage with the mobile app. Monetization happens in the app (via Apple, Google, etc.).',
};
