import { useEffect } from 'react';
import type { DataConfig } from '@/widgets/cohortMarketingPerformance';
import {
  dataConfigValidator,
  selectMetaIsLoaded,
  updateConfig,
} from '@/widgets/cohortMarketingPerformance';
import { ImportSharedView } from '@/features/ShareView';
import { ROUTES } from '@/shared/constants';
import { Analytics } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useViewInitializationStateMachineContext } from '@/shared/ui';

export function ImportSharedViewDialog() {
  const dispatch = useAppDispatch();
  const isColumnsMetaLoaded = useAppSelector(selectMetaIsLoaded);
  const { onSharedViewDetect, onSharedViewApply, onSharedViewError } =
    useViewInitializationStateMachineContext();

  useEffect(() => {
    onSharedViewDetect();
  }, [onSharedViewDetect]);

  const handleApplyView = (view: unknown) => {
    onSharedViewApply();
    dispatch(updateConfig(view as DataConfig));
    Analytics.sendFeatureUsage('share_view', 'apply', {
      page: ROUTES.cohortMarketingPerformance,
    });
  };
  const isViewValid = (view: unknown) => {
    const parseResult = dataConfigValidator.safeParse(view);

    return parseResult.success;
  };

  if (!isColumnsMetaLoaded) {
    return null;
  }

  return (
    <ImportSharedView
      isViewValid={isViewValid}
      applyView={handleApplyView}
      onViewError={onSharedViewError}
    />
  );
}
