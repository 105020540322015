import { startOfToday, startOfYesterday, subDays } from 'date-fns';
import { z } from 'zod';

export interface DateRange {
  from: Date;
  to: Date;
}

export type FilterDateRangeDTO = [string, string];

export type SerializedDateRange = Record<keyof DateRange, string>;

export enum DateRangePresets {
  ALL_TIME = 'allTime',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last7days',
  LAST_14_DAYS = 'last14days',
  LAST_30_DAYS = 'last30days',
  LAST_60_DAYS = 'last60days',
  LAST_90_DAYS = 'last90days',
}

export const CustomDateRangePreset = 'custom' as const;
export type CustomDateRangePresetKey = typeof CustomDateRangePreset;

export const serializedDateRangeValueObjectValidator = z
  .object({
    preset: z.nativeEnum(DateRangePresets),
  })
  .or(
    z.object({
      preset: z.literal(CustomDateRangePreset),
      dateRange: z.object({
        from: z.string().date(),
        to: z.string().date(),
      }),
    }),
  );

export type SerializedDateRangeValueObject = z.infer<
  typeof serializedDateRangeValueObjectValidator
>;

export const getRangeByPreset = (preset: DateRangePresets): DateRange => {
  const today = startOfToday();

  switch (preset) {
    case DateRangePresets.ALL_TIME:
      return {
        from: new Date(0),
        to: today,
      };
    case DateRangePresets.YESTERDAY:
      return {
        from: startOfYesterday(),
        to: startOfYesterday(),
      };
    case DateRangePresets.LAST_7_DAYS:
      return {
        from: subDays(today, 7),
        to: today,
      };
    case DateRangePresets.LAST_14_DAYS:
      return {
        from: subDays(today, 14),
        to: today,
      };
    case DateRangePresets.LAST_30_DAYS:
      return {
        from: subDays(today, 30),
        to: today,
      };
    case DateRangePresets.LAST_60_DAYS:
      return {
        from: subDays(today, 60),
        to: today,
      };
    case DateRangePresets.TODAY:
      return {
        from: today,
        to: today,
      };
    case DateRangePresets.LAST_90_DAYS:
    default:
      return {
        from: subDays(today, 90),
        to: today,
      };
  }
};

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const toSerialized = (range: DateRange): SerializedDateRange => {
  return {
    from: formatDate(range.from),
    to: formatDate(range.to),
  };
};

export const fromSerialized = (range: SerializedDateRange): DateRange => {
  const [year, month, day] = range.from.split('-').map(Number);
  const fromDate = new Date(year, month - 1, day);
  fromDate.setHours(0, 0, 0, 0);

  const [toYear, toMonth, toDay] = range.to.split('-').map(Number);
  const toDate = new Date(toYear, toMonth - 1, toDay);
  toDate.setHours(0, 0, 0, 0);

  return {
    from: fromDate,
    to: toDate,
  };
};
