import InfoIcon from '@mui/icons-material/ErrorOutlineRounded';
import type { SelectChangeEvent } from '@mui/material';
import { Box, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { WebLinkType } from '../../model/types';
import { WEB_LINKS_DESCRIPTIONS_MAP, WEB_LINKS_LABELS_MAP } from './constants';

interface Props {
  value: WebLinkType;
  onChange: (value: WebLinkType) => void;
}

export function WebLinkTypeSelect({ value, onChange }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as WebLinkType);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Select
        sx={{ width: 150 }}
        value={value}
        onChange={handleChange}
        size="small"
      >
        {Object.values(WebLinkType).map((type) => (
          <MenuItem key={type} value={type}>
            {WEB_LINKS_LABELS_MAP[type]}
          </MenuItem>
        ))}
      </Select>
      <Tooltip
        title={
          <Box>
            {Object.keys(WebLinkType).map((type) => {
              return (
                <Typography
                  key={type}
                  component="p"
                  gutterBottom
                  variant="caption"
                >
                  <b>{WEB_LINKS_LABELS_MAP[type as WebLinkType]}</b> -{' '}
                  {WEB_LINKS_DESCRIPTIONS_MAP[type as WebLinkType]}
                </Typography>
              );
            })}
          </Box>
        }
      >
        <InfoIcon color="info" />
      </Tooltip>
    </Box>
  );
}
