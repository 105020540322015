import { Box, Divider, Drawer, Typography } from '@mui/material';
import { useState } from 'react';
import { Analytics } from '@/shared/lib';
import { DrawerHeader } from '@/shared/ui';
import type { SavedViewStorage } from '../../api/types';
import { SavedViewsProvider } from '../SavedViewsContext/SavedViewsContext';
import { SaveViewForm } from '../SaveViewForm/SaveViewForm';
import { Views } from '../Views/Views';
import { ViewStatus } from '../ViewStatus/ViewStatus';

interface Props<T> {
  storage: SavedViewStorage;
  canApplyDefaultView: boolean;
  snapshotValidator: (
    v: unknown,
  ) => { success: true; data: T } | { success: false; error: unknown };
  currentViewState: unknown;
  onApply: (snapshot: unknown) => void;
}

export function SavedViews<T>({
  storage,
  canApplyDefaultView,
  snapshotValidator,
  currentViewState,
  onApply,
}: Props<T>) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    Analytics.sendFeatureUsage('saved_views', 'open_drawer', {
      page: window.location.pathname,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SavedViewsProvider
      storage={storage}
      canApplyDefaultView={canApplyDefaultView}
      snapshotValidator={snapshotValidator}
      currentViewState={currentViewState}
      onApply={onApply}
    >
      <ViewStatus onClick={handleOpen} />
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <DrawerHeader onClose={handleClose}>
          <Typography variant="h5">Custom views</Typography>
        </DrawerHeader>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            p: 4,
            width: 434,
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
          }}
        >
          <SaveViewForm />
          <Divider />
          <Views />
        </Box>
      </Drawer>
    </SavedViewsProvider>
  );
}
