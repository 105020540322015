import { Box } from '@mui/material';
import { useEffect, type FunctionComponent } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  AppleSearchAdsDatasource,
  AppLovinAdsDatasource,
  AppsflyerDatasource,
  AppStoreConnectDatasource,
  DatasourceIds,
  GoogleAdsDatasource,
  GoogleAnalyticsDatasource,
  GooglePlayConsoleDatasource,
  IronSourceDatasource,
  LiftoffDatasource,
  MetaAdsDatasource,
  MolocoAdsDatasource,
  PayPalDatasource,
  SnapchatAdsDatasource,
  StripeDatasource,
  TikTokForBusinessDatasource,
  UnityAdsDatasource,
  useDatasources,
  XAdsDatasource,
  MintegralDatasource,
  AppLovinMaxDatasource,
  TapJoyDatasource,
} from '@/widgets/datasources';
import { Header } from '@/widgets/Header';
import { ProfileMenu } from '@/widgets/ProfileMenu';
import { DatasourceHeader } from '@/features/DatasourceHeader';
import { selectIsFinished, setupIntegrations } from '@/entities/datasources';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Layout } from '@/shared/ui';

const DatasourceComponents: Record<DatasourceIds, FunctionComponent> = {
  [DatasourceIds.appleSearchAds]: AppleSearchAdsDatasource,
  [DatasourceIds.metaAds]: MetaAdsDatasource,
  [DatasourceIds.googleAds]: GoogleAdsDatasource,
  [DatasourceIds.snapchatAds]: SnapchatAdsDatasource,
  [DatasourceIds.tikTokForBusiness]: TikTokForBusinessDatasource,
  [DatasourceIds.appLovinAds]: AppLovinAdsDatasource,
  [DatasourceIds.googlePlayConsole]: GooglePlayConsoleDatasource,
  [DatasourceIds.appStoreConnect]: AppStoreConnectDatasource,
  [DatasourceIds.stripe]: StripeDatasource,
  [DatasourceIds.payPal]: PayPalDatasource,
  [DatasourceIds.unityAds]: UnityAdsDatasource,
  [DatasourceIds.molocoAds]: MolocoAdsDatasource,
  [DatasourceIds.ironSource]: IronSourceDatasource,
  [DatasourceIds.xAds]: XAdsDatasource,
  [DatasourceIds.liftoff]: LiftoffDatasource,
  [DatasourceIds.appsflyer]: AppsflyerDatasource,
  [DatasourceIds.googleAnalytics]: GoogleAnalyticsDatasource,
  [DatasourceIds.mintegral]: MintegralDatasource,
  [DatasourceIds.appLovinMax]: AppLovinMaxDatasource,
  [DatasourceIds.tapJoy]: TapJoyDatasource,
};

export function DatasourcePage() {
  const dispatch = useAppDispatch();
  const integrationsLoaded = useAppSelector(selectIsFinished);
  const { datasourceId } = useParams<{ datasourceId: DatasourceIds }>();
  const datasources = useDatasources();
  const activeDatasource = datasources.find((datasource) => {
    return datasource.id === datasourceId;
  });

  useEffect(() => {
    if (!activeDatasource || integrationsLoaded) {
      return;
    }

    dispatch(setupIntegrations());
  }, [dispatch, integrationsLoaded, activeDatasource]);

  if (!activeDatasource) {
    return <Navigate to=".." />;
  }

  const Component = DatasourceComponents[activeDatasource.id as DatasourceIds];

  return (
    <Layout
      headerSlot={
        <Header
          rightContentSlot={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              <ProfileMenu />
            </Box>
          }
        />
      }
      slotProps={{
        content: {
          sx: {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      }}
    >
      <DatasourceHeader datasource={activeDatasource} />
      <Component />
    </Layout>
  );
}
