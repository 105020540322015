import LinkIcon from '@mui/icons-material/InsertLinkRounded';
import { Box, Typography } from '@mui/material';
import { Header } from '@/widgets/Header';
import { ProfileMenu } from '@/widgets/ProfileMenu';
import { WebLinksTable } from '@/widgets/webLinks';
import { Layout } from '@/shared/ui';

export function WebLinksPage() {
  return (
    <Layout
      headerSlot={
        <Header
          rightContentSlot={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              <ProfileMenu />
            </Box>
          }
        />
      }
      slotProps={{
        content: {
          sx: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          },
        },
      }}
    >
      <Box
        sx={{
          py: 4,
          px: 5,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          boxShadow: '0px 8px 10px 0px #0023400D',
        }}
      >
        <LinkIcon sx={{ width: 32, height: 32 }} />
        <Typography variant="h4" color="secondary">
          Web links
        </Typography>
      </Box>
      <WebLinksTable />
    </Layout>
  );
}
