import type {
  DateRange,
  FilterDateRangeDTO,
  SerializedDateRangeValueObject,
} from '../types/dateRange';
import {
  CustomDateRangePreset,
  fromSerialized,
  getRangeByPreset,
} from '../types/dateRange';

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const prepareDateRangeValueObject = (
  value: SerializedDateRangeValueObject,
): FilterDateRangeDTO => {
  let range: DateRange;

  if (value.preset === CustomDateRangePreset) {
    range = fromSerialized(value.dateRange);
  } else {
    range = getRangeByPreset(value.preset);
  }

  return [formatDate(range.from), formatDate(range.to)];
};
