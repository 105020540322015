import type { WebLinkDTO } from '../api/types';
import { WebLinkTypeDTO } from '../api/types';
import type { WebLink } from '../model/types';
import { WebLinkType } from '../model/types';

export const mapWebLink = (dto: WebLinkDTO): WebLink => {
  return {
    id: dto.id,
    url: dto.url,
    type: mapWebLinkType(dto.type),
    productId: dto.productId,
  };
};

const mapWebLinkType = (type: WebLinkTypeDTO): WebLinkType => {
  switch (type) {
    case WebLinkTypeDTO.webApplication:
      return WebLinkType.webApplication;
    case WebLinkTypeDTO.webFunnel:
      return WebLinkType.webFunnel;
    case WebLinkTypeDTO.website:
      return WebLinkType.website;
    case WebLinkTypeDTO.web2App:
      return WebLinkType.web2App;
    default:
      throw new Error(`Invalid web link type: ${type}`);
  }
};
