import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import { useConfirmationDialog } from '@/shared/hooks';
import { TrashIcon } from '@/shared/ui';

interface Props {
  onDelete: () => Promise<void>;
}

export function ActionsMenu({ onDelete }: Props) {
  const { openDialog, dialogElememnt } = useConfirmationDialog({
    title: 'Delete web link',
    description: `Are you sure that you want to delete this web link?`,
    acceptButtonColor: 'error',
    acceptButtonText: 'Delete',
    onAccept: onDelete,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          disablePadding: true,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem dense onClick={openDialog}>
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
      {dialogElememnt}
    </>
  );
}
