import { baseApi, getErrorMessage } from '@/shared/api';
import { Monitoring } from '@/shared/lib';
import { mapProduct } from '../libs/mapProduct';
import type { ProductDTO } from './types';

export const getProducts = async () => {
  const { data, error } = await baseApi.functions.invoke<{
    products: ProductDTO[];
  }>('getOrganizationProducts');

  if (!data) {
    Monitoring.captureError([
      {
        slice: 'products',
        action: 'getProducts',
      },
      error,
    ]);

    throw new Error(getErrorMessage(error));
  }

  return data.products.map(mapProduct);
};
