import type { SelectChangeEvent } from '@mui/material';
import { Box, MenuItem, Select } from '@mui/material';
import { useMemo } from 'react';
import { selectProducts } from '@/entities/products';
import { useAppSelector } from '@/shared/model';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export function ProductSelect({ value, onChange }: Props) {
  const products = useAppSelector(selectProducts);
  const productsItems = useMemo<{ label: string; value: string }[]>(() => {
    return products
      .map((product) => ({
        label: product.name,
        value: product.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [products]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  return (
    <Select
      sx={{ width: 218 }}
      MenuProps={{ sx: { maxWidth: 218 } }}
      value={value}
      onChange={handleChange}
    >
      {productsItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          <Box
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            title={item.label}
          >
            {item.label}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
}
