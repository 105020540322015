import type { InputProps } from '@mui/material';
import { useCallback, useState } from 'react';

type OnBlurHandler = Exclude<InputProps['onBlur'], undefined>;
type OnChangeHandler = Exclude<InputProps['onChange'], undefined>;

interface Params {
  /**
   * Custom validation function that checks if the input value has an error
   * @param value - The current input value to check
   * @returns true if the value has an error, false if the value is valid
   */
  hasError?: (value: string) => boolean;
}

export const useValueInput = ({ hasError }: Params = {}): {
  error: boolean;
  value: string;
  onBlur: OnBlurHandler;
  onChange: OnChangeHandler;
} => {
  const [error, setError] = useState(false);
  const [value, setValue] = useState('');

  const onChange = useCallback<OnChangeHandler>(
    (event) => {
      setError(false);
      setValue(event.target.value);
    },
    [setValue],
  );
  const onBlur = useCallback<OnBlurHandler>(
    (event) => {
      const hasErrorValue = hasError
        ? hasError(event.target.value)
        : !event.target.value.trim();

      setError(hasErrorValue);
    },
    [setError, hasError],
  );

  return {
    error,
    value,
    onBlur,
    onChange,
  };
};
