import { DataStatus } from '@/shared/lib/dataFetching';
import { createAppSlice } from '@/shared/model';
import * as ProductsAPI from '../api/productsAPI';
import type { Product } from './types';

interface State {
  products: Product[];
  status: DataStatus;
}

const initialState: State = {
  products: [],
  status: DataStatus.idle,
};

export const productsSlice = createAppSlice({
  name: 'products',
  initialState,
  reducers: (create) => ({
    setupProducts: create.asyncThunk(
      (_: void) => {
        return ProductsAPI.getProducts();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.products = action.payload;
          state.status = DataStatus.finished;
        },
        rejected: (state) => {
          state.status = DataStatus.error;
        },
      },
    ),
  }),
  selectors: {
    selectProducts: (state) => {
      return state.products;
    },
    selectIsLoading: (state) => {
      return state.status === DataStatus.loading;
    },
    selectIsLoaded: (state) => {
      return state.status === DataStatus.finished;
    },
    selectIsFailed: (state) => {
      return state.status === DataStatus.error;
    },
  },
});

export const { setupProducts } = productsSlice.actions;
export const {
  selectProducts,
  selectIsLoaded,
  selectIsLoading,
  selectIsFailed,
} = productsSlice.selectors;
