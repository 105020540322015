export enum WebLinkType {
  webApplication = 'webApplication',
  webFunnel = 'webFunnel',
  website = 'website',
  web2App = 'web2App',
}

export interface WebLink {
  id: string;
  url: string;
  type: WebLinkType;
  productId: string;
}
