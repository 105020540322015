import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import {
  selectProducts,
  selectIsLoading as selectProductsLoading,
  selectIsFailed as selectProductsFailed,
  selectIsLoaded as selectProductsLoaded,
  setupProducts,
} from '@/entities/products';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Button, FatalError, Loader } from '@/shared/ui';
import {
  selectIsFailed as selectWebLinksFailed,
  selectWebLinks,
  setupWebLinks,
  selectIsLoading as selectWebLinksLoading,
  deleteWebLink,
} from '../../model/slice';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { AddWebLinkRow } from '../AddWebLinkRow/AddWebLinkRow';
import { WEB_LINKS_LABELS_MAP } from '../WebLinkTypeSelect/constants';

const MAX_PRODUCT_NAME_WIDTH = 250;

export function WebLinksTable() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const webLinks = useAppSelector(selectWebLinks);
  const isWebLinksError = useAppSelector(selectWebLinksFailed);
  const isWebLinksLoading = useAppSelector(selectWebLinksLoading);
  const products = useAppSelector(selectProducts);
  const isProductsLoading = useAppSelector(selectProductsLoading);
  const isProductsLoaded = useAppSelector(selectProductsLoaded);
  const isProductsError = useAppSelector(selectProductsFailed);
  const productNamesMap = useMemo(() => {
    return products.reduce<Record<string, string>>((acc, product) => {
      acc[product.id] = product.name;
      return acc;
    }, {});
  }, [products]);
  const [createEditorOpened, setCreateEditorOpened] = useState(false);

  useEffect(() => {
    dispatch(setupProducts());
    dispatch(setupWebLinks());
  }, [dispatch]);

  if (isWebLinksError || isProductsError) {
    return <FatalError />;
  }

  if (isProductsLoaded && products.length === 0) {
    return (
      <Typography variant="body1" sx={{ mx: 5, my: 3 }}>
        Products for your organization are not set up yet. Please contact{' '}
        <a href="mailto:support@campaignswell.com?subject=Setup products for organization">
          support
        </a>
      </Typography>
    );
  }

  const handleOpenCreateEditor = () => {
    setCreateEditorOpened(true);
  };
  const handleCloseCreateEditor = () => {
    setCreateEditorOpened(false);
  };

  const handleDelete = (id: string) => () => {
    return dispatch(deleteWebLink(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Web link successfully deleted.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete web link. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
      });
  };

  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table sx={{ maxWidth: 1100 }}>
        <TableHead>
          <TableRow>
            <TableCell>URL</TableCell>
            <TableCell sx={{ width: MAX_PRODUCT_NAME_WIDTH }}>
              Product
            </TableCell>
            <TableCell sx={{ width: 215 }}>Type</TableCell>
            <TableCell sx={{ width: 80 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {webLinks.length === 0 && !createEditorOpened && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No web links added
              </TableCell>
            </TableRow>
          )}
          {webLinks.map((webLink) => {
            return (
              <TableRow key={webLink.id} sx={{ position: 'relative' }}>
                <TableCell>{webLink.url}</TableCell>
                <TableCell>
                  <Tooltip title={productNamesMap[webLink.productId]}>
                    <Box
                      sx={{
                        maxWidth: MAX_PRODUCT_NAME_WIDTH,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {productNamesMap[webLink.productId]}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>{WEB_LINKS_LABELS_MAP[webLink.type]}</TableCell>
                <TableCell>
                  <ActionsMenu onDelete={handleDelete(webLink.id)} />
                </TableCell>
              </TableRow>
            );
          })}
          {createEditorOpened && (
            <AddWebLinkRow onClose={handleCloseCreateEditor} />
          )}
        </TableBody>
      </Table>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        disabled={createEditorOpened}
        onClick={handleOpenCreateEditor}
      >
        Add web link
      </Button>
      <Loader active={isProductsLoading || isWebLinksLoading} />
    </Box>
  );
}
