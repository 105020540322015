import type { ReactNode } from 'react';
import { createContext, useContext, useState, useCallback } from 'react';

interface ContextState {
  canApplyDefaultView: boolean;
  onSharedViewDetect: () => void;
  onSharedViewApply: () => void;
  onSharedViewError: () => void;
}

const Context = createContext<ContextState | null>(null);

enum States {
  noSharedView = 'noSharedView',
  sharedViewProcessing = 'sharedViewProcessing',
  sharedViewApplied = 'sharedViewApplied',
  sharedViewError = 'sharedViewError',
}

interface Props {
  children: ReactNode;
}

export const ViewInitializationStateMachineProvider = ({ children }: Props) => {
  const [state, setState] = useState<States>(States.noSharedView);
  const onSharedViewDetect = useCallback(() => {
    setState(States.sharedViewProcessing);
  }, []);
  const onSharedViewApply = useCallback(() => {
    setState(States.sharedViewApplied);
  }, []);
  const onSharedViewError = useCallback(() => {
    setState(States.sharedViewError);
  }, []);

  return (
    <Context.Provider
      value={{
        get canApplyDefaultView() {
          return (
            state === States.noSharedView || state === States.sharedViewError
          );
        },
        onSharedViewDetect,
        onSharedViewApply,
        onSharedViewError,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useViewInitializationStateMachineContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useViewInitializationStateMachineContext hook used outside <ViewInitializationStateMachineProvider />',
    );
  }

  return context;
};
