export const ROUTES = {
  home: '/',
  signin: '/sign-in',
  passwordReset: '/password-reset',
  passwordRestore: '/password-restore',
  cohortMarketingPerformance: '/cohort-marketing-performance',
  calendarInsights: '/calendar-insights',
  datasources: '/datasources',
  chartsView: '/charts-view',
  webLinks: '/web-links',
};
