export enum WebLinkTypeDTO {
  webApplication = 'webApplication',
  webFunnel = 'webFunnel',
  website = 'website',
  web2App = 'web2App',
}

export interface WebLinkDTO {
  id: string;
  url: string;
  type: WebLinkTypeDTO;
  productId: string;
}
