import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import {
  getError,
  URLValidatonErrorCode,
} from '../AddWebLinkRow/useAddWebLinkFields';

export function URLInput(props: TextFieldProps) {
  const errorMessage = props.error
    ? getErrorMessage(props.value as string)
    : '';

  return (
    <TextField fullWidth size="small" helperText={errorMessage} {...props} />
  );
}

const getErrorMessage = (value: string): string => {
  if (!value) {
    return 'Could not be empty.';
  }

  const error = getError(value)!;

  switch (error.code) {
    case URLValidatonErrorCode.wwwStart:
      return 'Should not start with "www".';
    case URLValidatonErrorCode.includesProtocol:
      return 'Should not include protocol.';
    case URLValidatonErrorCode.invalidURL:
    default:
      return 'Invalid URL.';
  }
};
