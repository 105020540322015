import { Box, Divider } from '@mui/material';
import { Suspense, lazy, useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import type { DataConfig } from '@/widgets/cohortMarketingPerformance';
import {
  CohortMarketingPerformanceFilters,
  CohortMarketingPerformanceToolbar,
  selectConfig as selectCohortMarketingPerformanceConfig,
  resetConfig,
  selectConfigDimensions,
  selectConfigMetrics,
  updateConfig,
  selectMetaIsLoading,
  selectMetaIsFailed,
  updateSorting,
  reorderDimensions,
  reorderMetrics,
  selectConfigSorting,
  getPage,
  downloadData,
  selectMetaConfig,
  selectMetaIsLoaded,
  deleteConfigDimension,
  deleteConfigMetric,
  getMetricsMinMax,
  reset,
  selectConfigRowHeight,
  updateConfigColumnsWidth,
  selectConfigColumnsWidth,
  dataConfigValidator,
  selectMetaAllDimensions,
  selectMetaAllMetrics,
  selectDateScale,
  selectDateRange,
  selectLifetime,
  selectFilters,
  selectProbabilisticAttribution,
  selectAdvaceSortableOptions,
  getTreeData,
  getTotalData,
  selectIsTreeDataGridView,
  selectConfigMetricsColoring,
  getDataUpdateInfo,
} from '@/widgets/cohortMarketingPerformance';
import { Header } from '@/widgets/Header';
import { ProfileMenu } from '@/widgets/ProfileMenu';
import type { DataGroup } from '@/widgets/TreeDataGrid';
import { DataUpdateInfo } from '@/features/DataUpdateInfo';
import { DownloadDataButton } from '@/features/DownloadData';
import { RefreshData, useRefresher } from '@/features/RefreshData';
import { SharedViewType, ShareView } from '@/features/ShareView';
import { TimeMachine } from '@/features/TimeMachine';
import { SavedViews, SavedViewStorage } from '@/entities/savedViews';
import { ROUTES } from '@/shared/constants';
import { useMetricsStabilizedValue } from '@/shared/hooks';
import { useDataFetcher } from '@/shared/hooks';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type { SortingItem } from '@/shared/types';
import {
  DataGridApiRefProvider,
  FatalError,
  Layout,
  Loader,
  useViewInitializationStateMachineContext,
  ViewInitializationStateMachineProvider,
} from '@/shared/ui';

const DataTable = lazy(() =>
  import('@/widgets/DataTable').then(({ DataTable }) => {
    return { default: DataTable };
  }),
);
const TreeDataGrid = lazy(() =>
  import('@/widgets/TreeDataGrid').then(({ TreeDataGrid }) => {
    return { default: TreeDataGrid };
  }),
);

function Content() {
  const dispatch = useAppDispatch();
  const isColumnsMetaLoading = useAppSelector(selectMetaIsLoading);
  const isColumnsMetaLoaded = useAppSelector(selectMetaIsLoaded);
  const isColumnsMetaFailed = useAppSelector(selectMetaIsFailed);
  const metaColumnsConfig = useAppSelector(selectMetaConfig);
  const metrics = useAppSelector(selectConfigMetrics);
  const dimensions = useAppSelector(selectConfigDimensions);
  const sorting = useAppSelector(selectConfigSorting);
  const rowHeight = useAppSelector(selectConfigRowHeight);
  const config = useAppSelector(selectCohortMarketingPerformanceConfig);
  const allDimensions = useAppSelector(selectMetaAllDimensions);
  const allMetrics = useAppSelector(selectMetaAllMetrics);
  const dateScale = useAppSelector(selectDateScale);
  const dateRange = useAppSelector(selectDateRange);
  const lifetime = useAppSelector(selectLifetime);
  const filters = useAppSelector(selectFilters);
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );
  const metricsColoring = useAppSelector(selectConfigMetricsColoring);
  const columnsWidth = useAppSelector(selectConfigColumnsWidth);
  const advaceSortableOptions = useAppSelector(selectAdvaceSortableOptions);
  const isTreeDataGridView = useAppSelector(selectIsTreeDataGridView);
  const viewToShare = useMemo(() => {
    return {
      type: SharedViewType.cohortMarketingPerformance,
      snapshot: config,
    };
  }, [config]);
  const { refresher, onRefresh } = useRefresher();
  const stableMetricsValue = useMetricsStabilizedValue(metrics);
  const { canApplyDefaultView } = useViewInitializationStateMachineContext();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const applyConfig = useCallback(
    (newConfig: unknown) => {
      dispatch(updateConfig(newConfig as DataConfig));
    },
    [dispatch],
  );
  const handleTimeMachineReset = () => {
    dispatch(resetConfig());
  };
  const handleDownloadAllData = () => {
    return dispatch(
      downloadData({
        metrics,
        dimensions,
        dateScale,
        dateRange,
        sorting,
        probabilisticAttribution,
        filters,
        allMetrics,
        allDimensions,
        lifetime,
      }),
    );
  };
  const handleReorderDimensions = (info: {
    id: string;
    oldIndex: number;
    newIndex: number;
  }) => {
    dispatch(reorderDimensions(info));
  };
  const handleReorderMetrics = (info: {
    id: string;
    oldIndex: number;
    newIndex: number;
  }) => {
    dispatch(reorderMetrics(info));
  };
  const handleUpdateSorting = (column: string, newItem?: SortingItem) => {
    dispatch(updateSorting({ column, item: newItem }));
  };
  const handleDeleteDimension = (id: string) => {
    dispatch(deleteConfigDimension(id));
  };
  const handleDeleteMetric = (id: string) => {
    dispatch(deleteConfigMetric(id));
  };
  const handleGetMetricsMinMax = useCallback(
    () => {
      return dispatch(
        getMetricsMinMax({
          dimensions,
          metrics,
          filters,
          dateRange,
          dateScale,
          probabilisticAttribution,
          lifetime,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      isTreeDataGridView,
      dimensions,
      stableMetricsValue,
      filters,
      dateRange,
      dateScale,
      probabilisticAttribution,
      lifetime,
      refresher,
    ],
  );
  const { data: minMax } = useDataFetcher(handleGetMetricsMinMax);
  const handleGetDataUpdateInfo = useCallback(
    () => {
      return dispatch(getDataUpdateInfo());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, refresher],
  );
  const handleUpdateColumnsWidth = useCallback(
    (columnName: string, newWidth?: number) => {
      dispatch(
        updateConfigColumnsWidth([
          {
            name: columnName,
            width: newWidth,
          },
        ]),
      );
    },
    [dispatch],
  );
  const handleGetTotalData = useCallback(
    () => {
      return dispatch(
        getTotalData({
          dimensions,
          metrics,
          dateRange,
          lifetime,
          filters,
          probabilisticAttribution,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      stableMetricsValue,
      dateRange,
      lifetime,
      filters,
      probabilisticAttribution,
      refresher,
    ],
  );
  const handleGetRowsPage = useCallback(
    (offset?: number) => {
      return dispatch(
        getPage({
          dimensions,
          dateScale,
          dateRange,
          sorting,
          probabilisticAttribution,
          lifetime,
          filters,
          offset,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      stableMetricsValue,
      dateScale,
      dateRange,
      sorting,
      probabilisticAttribution,
      lifetime,
      filters,
      refresher,
    ],
  );
  const handleGetTreeDataGridData = useCallback(
    (dataGroups: DataGroup[]) => {
      return dispatch(getTreeData(dataGroups));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      stableMetricsValue,
      dateScale,
      dateRange,
      lifetime,
      sorting,
      filters,
      probabilisticAttribution,
      refresher,
    ],
  );

  return (
    <Layout
      headerSlot={
        <Header
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              Cohort Marketing Performance
              <Divider
                flexItem
                orientation="vertical"
                sx={{ borderColor: 'currentColor' }}
              />
              <DataUpdateInfo onGetDataUpdateInfo={handleGetDataUpdateInfo} />
            </Box>
          }
          rightContentSlot={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              {isColumnsMetaLoaded && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    height: 40,
                    color: 'white',
                  }}
                >
                  <TimeMachine
                    snapshot={config}
                    onTravel={applyConfig}
                    onReset={handleTimeMachineReset}
                  />
                  <RefreshData onRefresh={onRefresh} />
                  <Divider
                    sx={{ borderColor: 'currentColor' }}
                    orientation="vertical"
                  />
                  <DownloadDataButton
                    dimensions={dimensions}
                    metrics={metrics}
                    config={metaColumnsConfig}
                    getAllDataRows={handleDownloadAllData}
                  />
                  <ShareView
                    linkPrefix={ROUTES.cohortMarketingPerformance}
                    view={viewToShare}
                  />
                  <SavedViews
                    canApplyDefaultView={canApplyDefaultView}
                    snapshotValidator={dataConfigValidator.safeParse}
                    storage={SavedViewStorage.cohortMarketingPerformance}
                    currentViewState={config}
                    onApply={applyConfig}
                  />
                </Box>
              )}
              <ProfileMenu />
            </Box>
          }
        />
      }
      slotProps={{
        content: {
          sx: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            py: 2,
            px: 5,
          },
        },
      }}
    >
      {isColumnsMetaFailed ? (
        <FatalError />
      ) : (
        <DataGridApiRefProvider>
          <CohortMarketingPerformanceFilters />
          <CohortMarketingPerformanceToolbar minMax={minMax} />
          <Suspense fallback={<Loader active={!isColumnsMetaLoading} />}>
            {isTreeDataGridView ? (
              <TreeDataGrid
                columnsWidth={columnsWidth}
                columnsConfig={metaColumnsConfig}
                dimensions={dimensions}
                metrics={metrics}
                sorting={sorting}
                rowHeight={rowHeight}
                minMax={minMax}
                metricsColoring={metricsColoring}
                reorderMetrics={handleReorderMetrics}
                updateSorting={handleUpdateSorting}
                deleteMetric={handleDeleteMetric}
                getData={handleGetTreeDataGridData}
                getTotal={handleGetTotalData}
                updateColumnsWidth={handleUpdateColumnsWidth}
              />
            ) : (
              isColumnsMetaLoaded && (
                <DataTable
                  columnsWidth={columnsWidth}
                  columnsConfig={metaColumnsConfig}
                  dimensions={dimensions}
                  metrics={metrics}
                  sorting={sorting}
                  rowHeight={rowHeight}
                  dimensionsAdvancedSortingOptions={advaceSortableOptions}
                  minMax={minMax}
                  metricsColoring={metricsColoring}
                  getRowsPage={handleGetRowsPage}
                  getTotal={handleGetTotalData}
                  reorderDimensions={handleReorderDimensions}
                  reorderMetrics={handleReorderMetrics}
                  updateSorting={handleUpdateSorting}
                  deleteDimension={handleDeleteDimension}
                  deleteMetric={handleDeleteMetric}
                  updateColumnsWidth={handleUpdateColumnsWidth}
                />
              )
            )}
          </Suspense>
        </DataGridApiRefProvider>
      )}
      <Loader active={isColumnsMetaLoading} />
      <Outlet />
    </Layout>
  );
}

export function CohortMarketingPreformancePage() {
  return (
    <ViewInitializationStateMachineProvider>
      <Content />
    </ViewInitializationStateMachineProvider>
  );
}
