import { Box, Divider, MenuItem, MenuList, SvgIcon } from '@mui/material';
import { format } from 'date-fns';
import type { MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import { FORMAT } from '@/shared/constants';
import { Analytics } from '@/shared/lib';
import type { DateRange, SerializedDateRangeValueObject } from '@/shared/types';
import {
  CustomDateRangePreset,
  DateRangePresets,
  fromSerialized,
  getRangeByPreset,
  toSerialized,
} from '@/shared/types';
import { Dropdown } from '../Dropdown/Dropdown';
import CalendarIcon from './ui/calendar.svg?react';
import { CustomDateRangeSelect } from './ui/CustomDateRangeSelect/CustomDateRangeSelect';
import type { ReasonKeys } from './ui/CustomDateRangeSelect/types';
import { Reason } from './ui/CustomDateRangeSelect/types';

const PRESETS_LABEL_MAP: Record<DateRangePresets, string> = {
  [DateRangePresets.ALL_TIME]: 'All time',
  [DateRangePresets.TODAY]: 'Today',
  [DateRangePresets.YESTERDAY]: 'Yesterday',
  [DateRangePresets.LAST_7_DAYS]: 'Last 7 days',
  [DateRangePresets.LAST_14_DAYS]: 'Last 14 days',
  [DateRangePresets.LAST_30_DAYS]: 'Last 30 days',
  [DateRangePresets.LAST_60_DAYS]: 'Last 60 days',
  [DateRangePresets.LAST_90_DAYS]: 'Last 90 days',
};
const ITEMS: { value: DateRangePresets; label: string }[] = [
  { value: DateRangePresets.ALL_TIME, label: 'All time' },
  { value: DateRangePresets.TODAY, label: 'Today' },
  { value: DateRangePresets.YESTERDAY, label: 'Yesterday' },
  { value: DateRangePresets.LAST_7_DAYS, label: 'Last 7 days' },
  { value: DateRangePresets.LAST_14_DAYS, label: 'Last 14 days' },
  { value: DateRangePresets.LAST_30_DAYS, label: 'Last 30 days' },
  { value: DateRangePresets.LAST_60_DAYS, label: 'Last 60 days' },
  { value: DateRangePresets.LAST_90_DAYS, label: 'Last 90 days' },
];

interface Props {
  withFuture?: boolean;
  value: SerializedDateRangeValueObject;
  onChange: (v: SerializedDateRangeValueObject) => void;
}

export function DateRangeSelect({ withFuture, value, onChange }: Props) {
  const [open, setOpen] = useState(false);
  const dateRange: DateRange = useMemo(() => {
    if (value.preset === CustomDateRangePreset) {
      return fromSerialized(value.dateRange);
    } else {
      return getRangeByPreset(value.preset);
    }
  }, [value]);
  const customSelected = value.preset === CustomDateRangePreset;

  const handleOptionSelect = (option: DateRangePresets) => () => {
    setOpen(false);
    onChange({ preset: option });
    Analytics.sendFeatureUsage('date_range_filter', 'change', {
      type: 'preset',
      page: window.location.pathname,
    });
  };
  const handleCustomOptionSelect = (event: MouseEvent) => {
    event.preventDefault();
    onChange({
      preset: CustomDateRangePreset,
      dateRange: toSerialized(
        getRangeByPreset(value.preset as DateRangePresets),
      ),
    });
    Analytics.sendFeatureUsage('date_range_filter', 'select_custom_option', {
      page: window.location.pathname,
    });
  };
  const handleCustomValueChange = (newValue: DateRange, reason: ReasonKeys) => {
    if (reason === Reason.apply) {
      onChange({
        preset: CustomDateRangePreset,
        dateRange: toSerialized(newValue),
      });
    }

    setOpen(false);
  };

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      labelProps={{
        startIcon: (
          <SvgIcon sx={{ width: 18, height: 18 }}>
            <CalendarIcon />
          </SvgIcon>
        ),
      }}
      popperProps={{
        placement: 'bottom-start',
      }}
      label={
        customSelected
          ? formatRange(dateRange)
          : PRESETS_LABEL_MAP[value.preset]
      }
    >
      <Box display="flex">
        <MenuList>
          {ITEMS.map((item) => {
            return (
              <MenuItem
                key={item.value}
                selected={!customSelected && item.value === value.preset}
                onClick={handleOptionSelect(item.value)}
              >
                {item.label}
              </MenuItem>
            );
          })}
          <MenuItem
            selected={customSelected}
            onClick={handleCustomOptionSelect}
          >
            Custom dates
          </MenuItem>
        </MenuList>
        {customSelected && (
          <>
            <Divider flexItem orientation="vertical" />
            <CustomDateRangeSelect
              withFuture={withFuture}
              value={dateRange}
              onChange={handleCustomValueChange}
            />
          </>
        )}
      </Box>
    </Dropdown>
  );
}

const formatRange = (range: DateRange) => {
  return `${format(range.from, FORMAT)} - ${format(range.to, FORMAT)}`;
};
