import { baseApi, getErrorMessage } from '@/shared/api';
import { Monitoring } from '@/shared/lib';
import { mapWebLink } from '../libs/mapWebLink';
import type { WebLink } from '../model/types';
import type { WebLinkDTO } from './types';

export const getWebLinks = async () => {
  const { data, error } = await baseApi.functions.invoke<{
    webLinks: WebLinkDTO[];
  }>('getOrganizationWebLinks');

  if (!data) {
    Monitoring.captureError([
      {
        slice: 'webLinks',
        action: 'getWebLinks',
      },
      error,
    ]);

    throw new Error(getErrorMessage(error));
  }

  return data.webLinks.map(mapWebLink);
};

export const addWebLink = async (
  payload: Omit<WebLink, 'id'>,
): Promise<WebLink> => {
  const { data, error } = await baseApi.functions.invoke<{
    webLink: WebLinkDTO;
  }>('createOrganizationWebLink', {
    body: payload,
  });

  if (!data) {
    if (error.status !== 400) {
      Monitoring.captureError([
        {
          slice: 'webLinks',
          action: 'addWebLink',
        },
        error,
      ]);
    }

    throw new Error(getErrorMessage(error));
  }

  return mapWebLink(data.webLink);
};

export const deleteWebLink = async (id: WebLink['id']): Promise<void> => {
  const { error } = await baseApi.functions.invoke<{
    webLink: WebLink;
  }>('deleteOrganizationWebLink', {
    body: { id },
  });

  if (error) {
    Monitoring.captureError([
      {
        slice: 'webLinks',
        action: 'deleteWebLink',
      },
      error,
    ]);

    throw new Error(getErrorMessage(error));
  }
};
